<template>
   <v-app>
    <v-container fluid>
      <v-row>
        <v-col cols="4">
          家族特別プラン 
        </v-col>
          <v-col cols="3">
          単価:1,000円 
        </v-col>
        </v-row>
     <v-row>
        <v-col cols="2">
      <v-subheader>数量</v-subheader>
      </v-col>
      <v-col cols="3">
        <v-text-field label="数量" value="10"/>
      </v-col>
      <v-col cols="3">
        <v-text-field label="値引き" value="10"/>
      </v-col>
      <v-col cols="3">
        <v-text-field label="価格" value="100"/>
      </v-col>
    </v-row>
      <v-row justify="center">
          <v-card width="100%" height=400>
            <iframe id="myiframe" width="100%" height="600px" src="https://tachibana-sousai.com/kasoushiki/"></iframe>
          </v-card>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
     import axios from 'axios';
    export default {
  name: 'app',
  data() {
    return {
        show1: false,
        page: 1,
        zipcode: '',
        prefecture: '',
        address: '',
        error_msg: '',
        params: {
            name: '',
            name_kana: '',
            address: '',
            delivery_time: '',
            coupon_code: '',
            inzip: '1234567',
            intel: '0839765215',
            inmph: '08012341234',
            zipcd: '7540001',
            suuji: 123456789,
            insuu: 12345678,
            price: '1,000',
            password: 'Password',
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => (`The email and password you entered don't match`),
            },
            money: {
      decimal: '.',
      thousands: ',',
      prefix: '',
      suffix: ' ',
      precision: 0,
    }
        },
       } 
   },
  
    methods: {
    //--------------------------------------------------------------------
    //部門マスタ追加更新
    //--------------------------------------------------------------------
    submit() {
            axios
           .get('/api/TpBsMstADD',{
               params: {
                MTpBsTnpCd: this.MTpBsTnpCd,
                MTpBsTnpNm: this.MTpBsTnpNm
               }
           });
        },
        //画面のリセット
        reset () {
            this.$refs.form.reset()
        },
   }
}
</script>